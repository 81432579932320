import React from "react";

const AttachToCompanySubComponent = () => {
    return (
        <div className="w-full h-16 bg-white">
            <div>
                <label className="font-light text-gray-700 dark:text-gray-200 text-sm" htmlFor="description">Description</label>
                <input id="description" type="text"
                       className="text-sm block w-full px-2 py-1 mt-2 text-gray-700 bg-white border-b-2 border-gray-300 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-gray-500 dark:focus:border-gray-500 focus:outline-none focus:ring text-sm"/>
            </div>
        </div>
    )
}

export default AttachToCompanySubComponent;