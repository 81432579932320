import React from 'react';
import RegisterComponent from "../components/RegisterComponent";


const Register = ()  => {
    return (
        <RegisterComponent/>
    )
}

export default Register;